const PROD_SERVER = 'https://api.dev.recouvreup.sandboxify.com';
let baseUrl = PROD_SERVER;

const host = window.location.host;

if(host.indexOf('localhost') === -1 && host.indexOf('testing') === -1){
  // SET PRODUCTION SETUP HERE
}

if(host.indexOf('localhost') !== -1){
  baseUrl = 'http://localhost:40443';
}


export default {
  baseUrl,
  version: `2.0.0`,
  apiUrl: `${baseUrl}/api`,
  jwtSecret : `!5Mv9ryqyk?-+-c*,Ir-ETE1o=]Q5:{d`,
  sireneApiToken : `3ec35421-da39-3d8a-921b-1cde94c5e810`,
  siteKey:'6LcuWPsiAAAAALGfVvV5vY-4IWiWiwMs9mrv2sRP'
 };
